@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import the 'Questrial' font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

/* Apply the 'Questrial' font to the body */
body {
  @apply font-sans;
}

/* Main Brand Colours */
:root {
  --cerulean: #004BFF;
  --storm: #1E2833;
  --cirrus: #F5F5F5; /* Off-white */
  --cool-grey-01: #68707C;
  --cool-grey-02: #AFB7C6;
  
  /* Extended Colours */
  --cerulean-light: #4C80FF;
  --cerulean-dark: #003BCC;
  --storm-light: #2D3946;
  --storm-dark: #121A22;
  --cool-grey-03: #495463;
  --cool-grey-04: #D0D4DB;
  
  /* Accent Colours */
  --cerulean-soft: #809FFF;
  --steel-blue: #33558C;
  --slate: #3B4C63;
  --nimbus: #E2E5E9;
  --mist: #F0F2F5;
  --alto: #C8CBD1;
  
  /* Neutral & Highlight Colours */
  --charcoal: #282C35;
  --light-silver: #BDC4CF;
  --ash: #7D8793;
  --shadow: #20252B;
  --highlight-blue: #72A4FF;
}